/**
 * Implements a custom Confirm Box
 */
class ConfirmMessage {
  /**
   * Constructor
   *
   * @param {String} message The message to Show
   * @param {Object} buttons Object to overwite default options
   */
  constructor(message = '', buttons = {}) {
    this.message = message
    this.buttons = {
      ok: 'OK',
      cancel: 'Cancel',
      ...buttons,
    }

    this.accept = this.accept.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  /**
   * Opens the Confirm Message
   *
   * @returns {Promise<unknown>}
   */
  open() {
    this.render()

    return new Promise(resolve => {
      this.modal.addEventListener('accept', () => {
        resolve(true)
        this.destroy()
      })
      this.modal.addEventListener('cancel', () => {
        resolve(false)
        this.destroy()
      })
    })
  }

  /**
   * Cleans up the Confirm Message intance
   */
  destroy() {
    this.modal.remove()
    this.modal = null
  }

  /**
   * Generates the required markup for the Confirm Message
   */
  render() {
    this.modal = document.createElement('div')
    this.modal.className = 'modal'

    const dialog = document.createElement('div')
    dialog.className = 'modal__dialog'

    const body = document.createElement('div')
    body.className = 'modal__body'
    body.innerHTML = `
      <div class="modal__body-mesage">
        ${this.message}
      <div>
    `

    const footer = document.createElement('div')
    footer.className = 'modal__footer'

    const modalButtons = document.createElement('div')
    modalButtons.className = 'modal__buttons'

    this.cancelButton = document.createElement('button')
    this.cancelButton.className = 'modal__button modal__button--cancel'
    this.cancelButton.innerHTML = this.buttons.cancel
    this.cancelButton.addEventListener('click', this.cancel)
    modalButtons.append(this.cancelButton)

    this.acceptButton = document.createElement('button')
    this.acceptButton.className = 'modal__button modal__button--accept'
    this.acceptButton.innerHTML = this.buttons.ok
    this.acceptButton.addEventListener('click', this.accept)
    modalButtons.append(this.acceptButton)

    footer.append(modalButtons)
    dialog.append(body)
    dialog.append(footer)
    this.modal.append(dialog)

    document.body.appendChild(this.modal)
  }

  /**
   * Accept method. Triggers the `accept` Event
   */
  accept() {
    this.modal.dispatchEvent(new CustomEvent('accept'))
  }

  /**
   * Cancel method. Triggers the `cancel` Event
   */
  cancel() {
    this.modal.dispatchEvent(new CustomEvent('cancel'))
  }
}

export default ConfirmMessage
