import ConfirmMessage from "./confirm-message"

const confirmBtn = {
  init() {
    const elements = document.querySelectorAll('.btn--confirm')
    Array.from(elements).forEach(el => {
      el.addEventListener('click', async e => {
        e.preventDefault()
        const { message = '', ok = 'OK', cancel = 'Cancel' } = el.dataset
        const form = el.closest('form')
        const confirm = new ConfirmMessage(message, {
          ok,
          cancel
        })
        const response = await confirm.open()

        if (response && form) {
          form.submit()
        }
      })
    })
  }
}

export default confirmBtn
