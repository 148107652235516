/**
 * Triggers a callback function once the DOM is ready
 *
 * @param {function} callback The function to trigger
 */
export default function DOMReady(callback) {
  if (document.readyState !== 'loading') {
    callback()
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      callback()
    })
  }
}
