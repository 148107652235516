import Vue from 'vue'
import DOMReady from './utils/DOMReady'
import confirmBtn from './modules/confirm-button'

Vue.component('password-input', require('./components/PasswordInput.vue').default);
Vue.component('image-input', require('./components/ImageInput.vue').default);
Vue.component('true-false', require('./components/TrueFalse.vue').default);

new Vue({ el: '#app' })

DOMReady(() => {
  confirmBtn.init()
})