<template>
  <div class="toggle-switch-wrapper">
    <input :name="name" v-model="toggle" @keydown="keyboard" ref="input">
    <div class="toggle-switch" @click="toggleValue" :class="{'on': toggle}">
      <a type="button" class="toggle"></a>
      <span class="yes" v-if="toggle">{{ yes }}</span>
      <span class="no" v-else>{{ no }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number],
      required: true,
      default: false
    },
    name: {
      type: String,
      required: true,
      default: 'true-false'
    },
    yes: {
      type: String,
      required: false,
      default: 'Yes'
    },
    no: {
      type: String,
      required: false,
      default: 'No'
    },
  },
  data() {
    return {
      toggle: false
    }
  },
  methods: {
    toggleValue() {
      this.$refs.input.focus()
      this.toggle = this.toggle ? false : true
      this.$emit('input', this.toggle)
    },
    keyboard(e) {
      switch (e.keyCode) {
        case 32:
          e.preventDefault()
          this.toggleValue()
          break
        case 9:
          return false;
          break
        default:
          e.preventDefault()
      }
    }
  },
  created() {
    this.toggle = Boolean(this.value)
  }
}
</script>

<style lang="scss" scoped>
$white: #fff;
$background: #fcfcfc;
$grey: #e1e2e3;
$darkest-grey: #36435a;
$selected: #5ea357;

.toggle-switch {
  height: 45px;
  width: 120px;
  position: relative;
  border-radius: .5rem;
  border-style: solid;
  border-width: 1px;
  border-color: $darkest-grey;
  color: $darkest-grey;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s ease;
  user-select: none;

  .yes,
  .no {
    font-size: 14px;
    width: 60px;
    height: 45px;
    line-height: 45px;
    z-index: 1;
    position: absolute;
    top: 0;
    text-align: center;
  }

  .yes {
    left: 0;
    padding-left: 2px;
    color: $white;
  }

  .no {
    right: 0;
    padding-right: 2px;
  }

  .toggle {
    position: absolute;
    height: 39px;
    width: 54px;
    top: 2px;
    left: 2px;
    border: 1px solid $grey;
    padding: 0;
    background: $background;
    border-radius: .425rem;
    transition: all .3s ease;
    outline: none;
    z-index: 2;
  }

  &.on {
    border-color: $selected;
    background: $selected;
    color: $white;

    .toggle {
      background: $white;
      border-color: $white;
      left: 62px;
    }
  }

  &-wrapper {
    position: relative;
    height: 45px;
    width: 120px;
    position: relative;
    border: 0;
    background: transparent;
    padding: 0;

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
      left: 0;
      top: 0;

      &:focus {
        + .toggle-switch {
          border-color: $selected;
          box-shadow: 0 0 0 .2rem rgba($selected,.25);
        }
      }
    }
  }
}

</style>
