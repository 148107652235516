<template>
  <div class="image-input">
    <picture class="image-input__picture" :style="{ backgroundImage: `url(${defaultImage})`}">
      <img :src="imageSrc" :alt="name" v-if="imageSrc">
    </picture>
    <input 
      :id="name" 
      :name="name" 
      ref="image" 
      type="file" 
      class="form__input"
      @change="imageSelected" 
      @input="imageSelected"
      accept="image/x-png,image/gif,image/jpeg">
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: true,
      default: 'image'
    },
    defaultImage: {
      type: String,
      required: false,
      default: '/images/user-default.svg'
    }
  },
  data() {
    return {
      imageSrc: '',
      imageField: ''
    }
  },
  methods: {
    imageSelected(e){
			this.imageField = e.target.files[0]

			const reader = new FileReader()

			reader.onloadend = () => {
				this.imageSrc = reader.result
			}

      if (this.imageField) {
        reader.readAsDataURL(this.imageField)
        this.$emit('input', this.imageField)
      }
		},
  },
  created() {
    this.imageSrc = this.value ? this.value : ''
  }
}
</script>

<style lang="scss">
  .image-input {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    &__picture {
      position: relative;
      display: block;
      width: 100%;
      height: 1px;
      padding-bottom: 100%;
      border: 1px solid #ced4da;
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;

      img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .btn {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
      }
    }
  }
</style>